import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import Layout from "../components/common/layout"
import SEO from "../components/seo"
import JavaIcon from "../images/jobs/icons/java.inline.svg"
import JavascriptIcon from "../images/jobs/icons/javascript.inline.svg"
import NetIcon from "../images/jobs/icons/net.inline.svg"
import SalesforceIcon from "../images/jobs/icons/salesforce.inline.svg"
import PythonIcon from "../images/jobs/icons/python.inline.svg"
import ReactIcon from "../images/jobs/icons/react.inline.svg"
import { Card, CardContent, Container, Grid, Icon } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const renderDevLogo = (language) => {
  switch (language.toLowerCase()) {
    // case "analyst":
    //   return <AnalystIcon style={{ height: "2em", width: "auto", verticalAlign: "bottom" }}/>
    case "jvm":
      return <JavaIcon style={{ height: "2em", width: "auto", verticalAlign: "bottom" }} />
    case "javascript":
      return <JavascriptIcon style={{ height: "2em", width: "auto", verticalAlign: "bottom" }} />
    case "net":
      return <NetIcon style={{ height: "2em", width: "auto", verticalAlign: "bottom" }} />
    case "python":
      return <PythonIcon style={{ height: "2em", width: "auto", verticalAlign: "bottom" }} />
    case "react":
      return <ReactIcon style={{ height: "2em", width: "auto", verticalAlign: "bottom" }} />
    case "salesforce":
      return <SalesforceIcon style={{ height: "2em", width: "auto", verticalAlign: "bottom" }} />
    default:
      return <Icon name={"code"} size={"large"} style={{ verticalAlign: "bottom" }} />
  }
}

const JobOpeningsPage = () => {
  const data = useStaticQuery(graphql`
    query JobsQuery {
      allMdx(
        filter: { fileAbsolutePath: { regex: "/(jobs)/.*\\\\.mdx$/" } }
        sort: { fields: [exports___metadata___date], order: DESC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            exports {
              metadata {
                title
                description
                location
                subCategory
              }
            }
          }
        }
      }
    }
  `)

  const useStyles = makeStyles((theme) => ({
    techArea: {
      [theme.breakpoints.down("md")]: {
        padding: "7em 0",
      },
      [theme.breakpoints.up("md")]: {
        margin: "10em 0",
      },
    },
    techTitle: {
      display: "inline",
      paddingLeft: ".5em",
      fontSize: "1.35em",
      fontWeight: "bold",
      textTransform: "capitalize",
    },
    jobCardTitle: {
      fontSize: "1.25em",
      fontWeight: "600",
      marginBottom: 0,
    },
    jobCardMeta: {
      color: "#808080",
      padding: 0,
      margin: 0,
    },
    jobCardLink: {
      color: "white",
      textDecoration: "none",
    },
    formLabel: {
      textTransform: "capitalize",
    },
  }))
  const classes = useStyles()

  const listJobs = (jobs) => {
    return (
      <Grid container className={classes.techArea} spacing={4}>

        {/*<Hidden mdDown>*/}
        {/*  <Grid item xs={10} md={3}>*/}
        {/*    <FormControl component="fieldset">*/}
        {/*      <FormLabel component="legend">{"Tecnologías"}</FormLabel>*/}
        {/*      <FormGroup>*/}
        {/*        {*/}
        {/*          Object.keys(_.groupBy(jobs, "node.exports.metadata.subCategory")).map((language) =>*/}
        {/*            <FormControlLabel*/}
        {/*              control={<Checkbox checked={false} size={"small"} onChange={handleChange} name={language} />}*/}
        {/*              label={language}*/}
        {/*              className={classes.formLabel} />,*/}
        {/*          )*/}
        {/*        }*/}
        {/*      </FormGroup>*/}
        {/*    </FormControl>*/}
        {/*  </Grid>*/}
        {/*</Hidden>*/}

        <Grid container spacing={4} md={12}>
          {
            jobs.map(({ node }) =>
              <Grid item xs={12} md={4}>
                <Link to={node.fields.slug} className={classes.jobCardLink}>
                  <Card variant={"outlined"} style={{ height: "100%", backgroundColor: "#f9f9f9" }}>
                    <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                      <p className={classes.jobCardTitle}>{node.exports.metadata.title}</p>
                      <p className={classes.jobCardMeta}>{node.exports.metadata.location}</p>
                      <p>{node.exports.metadata.description}</p>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>,
            )}
        </Grid>

      </Grid>
    )
  }

  return (
    <>
      <SEO
        title={"Búsquedas IT"}
        description={"Búsquedas laborales IT"}
        keywords={["jobs", "trabajos", "it"]}
        url={"https://www.waravix.com/jobs"}
      />
      <Layout currentPage={"jobs"}>
        <Container>
          {listJobs(data.allMdx.edges)}
        </Container>
      </Layout>
    </>
  )
}

export default JobOpeningsPage
